.loaderBar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 4px;
  z-index: 99999;
  &::after {
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    transform-origin: left;
    transform: scaleX(0);
    display: block;
    width: 100%;
    background: #000;
    height: 4px;
    :global(.rtl) & {
      transform-origin: right;
    }
  }
}

.loaderBar.loading::after {
  transform: scaleX(0.9);
  transition: transform 9s;
}
