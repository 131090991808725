
.section {
	background-color: #f5f7f9;
}

.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
	> *  {
		margin-bottom: 40px;
	}
}

.heading {
	font-size: 24px;
	font-weight: 600;
	letter-spacing: -0.69px;
	color: #1a1a1a;
	margin-top: 20px;
}

.text {
	font-size: 14px;
	line-height: 1.43;
	text-align: center;
	color: #5f738c;
	margin-top: 14px;
}

.svg {
	svg {
    width: 280px;
  }
  @media (min-width: 768px) {
    svg {
      width: auto;
    }
  }
}

.button {
	background: var(--theme-color-secondary);
	border-radius: 4px;
	color: #FFF;
	font-size: 16px;
	font-weight: 600;
	padding: 10px 16px;
	line-height: 1.25;
}